<template>
	<div class="search-box">
		<div class="search-title">查询我的保障</div>
		<div class="search-content">
			<div class="content-title">请输入您的姓名、证件号和保单号</div>
			<van-form class="form" ref="form" input-align="right" error-message-align="right">
				<van-cell-group :border="false">
					<van-field :label="$t('name')" v-model="userInfo.assuredName" label-class="cell_title" value-class="cell_value" :rules="rules.assuredName" :placeholder="$t('namePlaceholder')" />
					<van-field :label="$t('cardNo')" v-model="userInfo.assuredIdNumber" label-class="cell_title" value-class="cell_value" :rules="rules.assuredIdNumber" :placeholder="$t('assuredCardNo')" />
					<van-field :label="$t('policyCode')" v-model="userInfo.policyCode" label-class="cell_title" value-class="cell_value" :placeholder="$t('policyCodePlaceholder')" />
				</van-cell-group>
			</van-form>
		</div>
		<div class="btn-box">
			<van-button class="loading_btn" @click="search" :loading="loading" type="info" color="#2594EF">下一步</van-button>
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, Form, Field, Button } from 'vant';
export default {
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[Form.name]: Form,
		[Button.name]: Button,
	},
	data() {
		return {
			userInfo: {
				assuredName: '',
				assuredIdNumber: '',
				policyCode: '',
			},
			rules: {
				assuredName: [{ required: true, message: '' }],
				assuredIdNumber: [{ required: true, message: '' }],
			},
			loading: false,
		};
	},
	methods: {
		search() {
			this.$refs.form.validate().then(() => {
				this.$router.push({
					path: '/pFileSearch/list',
					query: this.userInfo,
				});
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.search-box {
	font-size: 0.3rem;
	width: 86%;
	margin: 3rem auto 0;
}
.search-title {
	color: $color_main;
	font-weight: bold;
	text-align: center;
}
.search-content {
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	margin-top: 0.3rem;
	border-radius: 10px;
	padding: 0.6rem 0 0.3rem;
}
.content-title {
	font-size: 0.26rem;
	text-align: center;
	margin-bottom: 0.3rem;
}
.btn-box {
	margin-top: 0.4rem;
	width: 100%;
	.loading_btn {
		width: 100%;
		border-radius: 10px;
	}
}
.van-cell {
	margin-bottom: 0.2rem;
}
</style>
